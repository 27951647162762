.staticCommonView {
  background: #fff;
  float: left;
}

.staticCommonView #main-container {
  padding-top: 0;
  position: relative;
}

.contact-customer{
  font-family: "NotoSansBold", sans-serif !important;
}

@media (min-width: 1280px) {
  .staticCommonView .container {
    width: 1280px;
    max-width: 1280px;
  }
  .staticCommonView {
    min-width: 1365px;
  }
  .Section1 {
    margin: 0 0 0 2.5rem !important;
    max-width: 1280px !important;
  }
  .common-alert-banner {
    padding: 0px !important;
  }
}

.staticCommonView .container:before {
  display: table;
  content: " ";
}

.home-wrap {
  margin-bottom: 35px;
}

.row:before,
.row:after {
  display: table;
  content: " ";
}

// .col-md-12 {
//     padding-right: 20px;
//     padding-left: 20px;
// }

.why-shoppe h2 {
  font-size: 2em;
  color: #73aed3;
  text-align: center;
  font-family: "Poppins-Medium", sans-serif;
  margin: 42px 0;
}

.shoppe-benifits {
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .LoginPageTemplate .LeftContentSlot .customer-login {
    width: 100% !important;
    position: relative !important;
  }
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A {
    max-width: 100%;
    --cx-flex-basis: 100%;
  }
  cx-page-layout.LandingPage2Template cx-page-slot.Section2B {
    --cx-flex-basis: 100%;
    max-width: 100%;
    position: relative;
  }
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A .home-banner-left {
    position: absolute;
    z-index: 2;
    padding-top: 129px;
    width: 391px;
    text-align: left;
    color: #2a343f;
    line-height: 44px;
    margin: 0;
    font-family: "Poppins-medium", sans-serif;
    h2 {
      font-size: 40px;
    }
  }

  cx-page-layout.LandingPage2Template cx-page-slot.Section2B .user-form {
    position: absolute !important;
    justify-content: flex-end !important;
    width: 100%;
  }
  .row-20 {
    margin-right: -20px;
    margin-left: -20px;
  }
  .staticCommonView .container,
  #welcome-banner {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.shoppe-benifits h3 {
  color: #2a343f;
  font-size: 1.33em;
  letter-spacing: 0.5px;
  font-family: "Poppins-Medium", sans-serif;
  margin: 16px 0 12px 0;
}

.shoppe-benifits p {
  line-height: 22px;
  font-size: 1.17em;
  font-family: "Poppins-Medium", sans-serif;
}

.row:after {
  clear: both;
}

.container:after {
  clear: both;
  display: table;
  content: " ";
}

@media only screen and (max-width: 479px) {
  .staticCommonView #main-container {
    overflow: hidden;
  }
}

@media only screen and (max-width: 480px) {
  .staticCommonView .container {
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .home-wrap {
    margin-bottom: 0;
  }
  .acc-warning-box p {
    margin: 18px 0 -2px 2px !important;
  }
}

@media only screen and (max-width: 480px) {
  .row-20 {
    margin-right: -12px;
    margin-left: -12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .why-shoppe h2 {
    margin-top: 36px;
  }
  .shoppe-benifits p {
    margin-bottom: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .staticCommonView #main-container {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  #welcome-banner {
    min-width: 1280px;
  }
}

.bdr-blue {
  border-bottom: 4px solid #edf6fb;
}

.p0 {
  padding: 0;
}

.acc-warning-box {
  min-height: 104px;
  background-color: #fff;
  margin-bottom: 4px;
  border-left: 4px solid #0056a6;
}

.margin0 {
  margin: 0;
}

.nopadding-nomargin {
  padding: 0;
  margin: 0;
  width: 91%;
}

.acc-warning-box p {
  margin: 18px 0 -2px 28px;
  padding: 0;
}

#shippingDelays {
  padding-left: 39px !important;
}

.acc-warning-box .warning-text {
  font-family: "Poppins-medium", sans-serif;
  font-size: 1.67em;
  font-weight: 500;
  color: #2a343f;
  margin-left: 15px;
}

.infoBanner span {
  padding-left: 35px;
}

.px0 {
  padding-left: 0;
  padding-right: 0;
}

.list-type-disc {
  list-style-type: inherit;
  font-size: 0.6em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #5b646e;
  padding: 0px 0 0 34px;
  font-family: "NotoSans", sans-serif;
}

.Notify-Symbol {
  display: inline-block;
  width: 100%;
  margin: 0 0 12px 0;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .acc-warning-box .warning-text {
    display: inline-block;
    width: 87%;
    font-size: 1.67em !important;
    line-height: 24px !important;
  }
}

.acc-warning-box .alert-close {
  padding: 0 13px 0px 0;
  width: 8.33%;
}

.bannerHide {
  display: none !important;
}

cx-page-layout.LandingPage2Template cx-page-slot.Section1 {
  margin-top: 0px !important;
}

@media (min-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A {
    max-width: 100%;
    --cx-flex-basis: 100%;
  }
  cx-page-layout.LandingPage2Template cx-page-slot.Section2B {
    --cx-flex-basis: 100%;
    max-width: 100%;
    position: relative;
  }
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A .home-banner-left {
    position: absolute;
    z-index: 2;
    width: 350px;
    padding: 0px 0px 0px 0px !important;
    text-align: left;
    color: #2a343f;
    line-height: 44px;
    margin: 0;
    font-family: "Poppins-medium", sans-serif;
    h2 {
      font-size: 30px;
    }
  }

  cx-page-layout.LandingPage2Template cx-page-slot.Section2B .user-form {
    position: absolute !important;
    justify-content: flex-end !important;
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A .home-banner-left {
    position: absolute;
    z-index: 2;
    padding: 0px 0px 0px 0px !important;
    text-align: left;
    color: #2a343f;
    line-height: 44px;
    margin: 0;
    font-family: "Poppins-medium", sans-serif;
    h2 {
      font-size: 30px !important;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A .home-banner-left {
    width: 358px;
  }
  cx-page-layout.LoginPageTemplate .customer-login {
    width: 43%;
    position: fixed;
  }
}

cx-page-layout.LoginPageTemplate {
  .customer-login {
    width: 43%;
  }
}
@media (min-width: 992px) {
  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    width: 31%;
    position: relative;
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    position: relative !important;
    width: 100% !important;
    left: 25px;
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    width: 40%;
    position: relative;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    display: block;
    width: 48%;
    position: relative;
  }
}
